<template>
  <b-modal
    id="modal-special-offer"
    class="custom-modal1"
    :hide-footer="true"
    modal-class="custom-modal1"
    @hidden="onHidden"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <a href="#" class="close-button" @click="close()"> </a>
      <div class="headline" :class="$store.state.locale"></div>
    </template>

    <template slot="default">
      <div
        v-if="rewards[0]"
        class="offer-reward-container1 d-flex flex-column align-items-center justify-content-between"
      >
        <div class="icon-gold"></div>
        <div
          v-if="rewards[0].gold > 0"
          class="amount-gold golden-gradient-text"
        >
          {{ formatNumber(rewards[0].gold) }}
        </div>
        <div
          v-if="rewards[0].crown > 0"
          class="w-100 d-inline-flex align-items-center justify-content-center"
        >
          <div class="amount-crown golden-gradient-text">
            {{ formatNumber(rewards[0].crown) }} x
          </div>
          <div class="icon-crown ms-1"></div>
        </div>

        <div
          class="w-100 package-bonus-icons d-inline-flex align-items-center justify-content-around"
        >
          <div v-if="rewards[0].card > 0" class="package-item">
            <div class="icon cardstack"></div>
            <div class="text">{{ formatNumber(rewards[0].card) }} x</div>
          </div>

          <div v-if="rewards[0].loyalty > 0" class="package-item">
            <div class="icon loyalty"></div>
            <div class="text">{{ formatNumber(rewards[0].loyalty) }} x</div>
          </div>

          <div v-if="rewards[0].elixir > 0" class="package-item">
            <div class="icon elixir"></div>
            <div class="text">{{ formatNumber(rewards[0].elixir) }} x</div>
          </div>

          <div v-if="rewards[0].expbooster > 0" class="package-item">
            <div class="icon expbooster"></div>
            <div class="text">
              {{ Math.round(rewards[0].expbooster / 60) }} Min.
            </div>
          </div>
        </div>
      </div>
      <div class="submit-button pt-3" @click="buyItem(0, 'Offer', 'Special')">
        <p class="bold text-uppercase text-white m-0">
          {{ $t("shop.offers.buy_button", { 0: "" }) }}
        </p>

        <div class="big bold text-white m-0 position-relative">
          {{ formatPrice(price[0]) }} €

          <div
            v-if="oldPricesSpecial && oldPricesSpecial[0]"
            class="offer-old-price"
          >
            {{ formatPrice(oldPricesSpecial[0]) }} €
            <div class="offer-red-line to-center-abs"></div>
          </div>
          <div
            v-if="moreContentSpecial && moreContentSpecial[0]"
            class="big-badge-more-percent smaller"
          >
            <span class="big">{{ moreContentSpecial[0] }}</span>
            {{ $t("shop.offers.more_contents2") }}
          </div>
        </div>
      </div>

      <div class="badge-big">
        <div>
          <i18next :translation="$t('shop.offers.badge4a')" class="badge-text"
            ><template #0
              ><div class="big">
                {{ validDuration }}
              </div></template
            ></i18next
          >
        </div>
        <div
          class="countdown-box d-flex flex-column align-items-center justify-content-around position-absolute w-100 text-uppercase medium"
        >
          <span>{{ $t("shop.offers.countdown.time_remaining") }}</span>
          <div
            v-if="$store.getters['shop/getSpecialOfferTime'] > 360000"
            class="w-75 d-inline-flex align-items-center justify-content-around"
          >
            <span>{{ $t("time.hour.abbr") }}</span
            ><span>M</span> <span>S</span>
          </div>

          <CountdownDigital
            :time="$store.getters['shop/getSpecialOfferTime']"
            :with-time-diff="true"
          ></CountdownDigital>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import CountdownDigital from "@/components/base/countdown-digital.vue";
import offerModalContent from "@/mixins/offerModalContent.js";
export default {
  name: "ModalSpecialOffer",
  components: { CountdownDigital },
  mixins: [offerModalContent],
  data() {
    return {
      isSpecialOffer: true,
      isPipelineOffer: true,
    };
  },

  computed: {
    validDuration() {
      const offer = this.$store.state.shop.specialOffer;
      if (!offer || !offer.configuration) {
        return;
      }
      const conf = JSON.parse(offer.configuration);
      return (
        (conf.temporal.amount / 24).toFixed(2) + " " + this.$t("time.day.other")
      );
    },

    oldPricesSpecial() {
      const offer = this.$store.state.shop.specialOffer;
      if (!offer || !offer.configuration) {
        return;
      }
      const conf = JSON.parse(offer.configuration);
      return conf.old_prices;
    },

    moreContentSpecial() {
      const offer = this.$store.state.shop.specialOffer;
      if (!offer || !offer.configuration) {
        return;
      }
      const conf = JSON.parse(offer.configuration);
      return conf.more_content;
    },
  },
  created() {
    this.$root.$on("show-special-offer", () =>
      this.$bvModal.show("modal-special-offer")
    );
  },
  mounted: function () {
    if (this.$store.state.shop.autoShowSpecialOffer) {
      this.$bvModal.show("modal-special-offer");
      this.deactivateAutoShow();
    } else {
      this.$store.commit("popups/setNextPipelineQueueStep");
    }
  },
  methods: {
    deactivateAutoShow() {
      if (!this.$store.state.shop.autoShowSpecialOffer) {
        return;
      }
      this.$store.commit("shop/setAutoShowSpecialOffer", false);
    },
  },
};
</script>
<style lang="scss">
$modal-width: 1012px;
$modal-height: 776px;

#modal-special-offer {
  .modal-dialog {
    top: 50px;
    max-width: $modal-width;
    height: $modal-height;

    @media (max-height: 830px) {
      transform: scale(0.8);
      top: -100px;
      margin-bottom: -20%;
    }
  }

  .modal-content {
    width: $modal-width;
    background: url(~@/assets/img/shop/offers/offer-overlay-specialoffer-glueckstag-1-panel.png)
      center/100% 100% no-repeat;
  }

  .close-button {
    top: 50px;
    right: -30px;
  }
}
</style>

<style scoped lang="scss">
#modal-special-offer {
  .headline {
    width: 605px;
    height: 250px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 50px;
    &.en {
      background: url(~@/assets/img/shop/offers/specialoffer-hl-en.png) top
        left/557px 234px no-repeat;
    }
    &.de {
      background: url(~@/assets/img/shop/offers/specialoffer-hl-de.png) top
        left/605px 208px no-repeat;
    }
  }

  .offer-reward-container1 {
    top: 175px;
    left: 282px;
    width: 398px;
    position: absolute;

    .icon-gold {
      width: 218px;
      height: 134px;
      background: url(~@/assets/img/common/three-coins.png) center/100% 100%
        no-repeat;
    }

    .amount-gold {
      font-size: 50px;
      font-family: Ubuntu-Bold;
      position: relative;
      display: inline-flex;
      text-align: center;
      margin-top: 15px;
      line-height: 1;
      filter: drop-shadow(0 0 12px rgba(0, 0, 0, 0.5))
        drop-shadow(0 0 6px rgba(0, 0, 0, 0.8));
    }

    .amount-crown {
      font-size: 48px;
      font-family: Ubuntu-Bold;
      filter: drop-shadow(0 0 15px rgba(0, 0, 0, 0.5))
        drop-shadow(0 0 7px rgba(0, 0, 0, 0.8));
    }
    .icon-crown {
      width: 70px;
      height: 57px;
      background: url(~@/assets/img/common/crown6.png) center/100% 100%
        no-repeat;
    }

    .package-bonus-icons {
      margin-top: 44px;
      padding: 0 40px;

      .package-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100px;
        font-size: 16px;

        .text {
          color: $light-blue;
          font-family: Ubuntu-Medium;
          font-size: 24px;
        }
        .icon {
          &.cardstack {
            width: 46px;
            height: 51px;
            background: url(~@/assets/img/common/cardstack-3.png) center/100%
              100% no-repeat;
          }
          &.loyalty {
            width: 52px;
            height: 53px;
            background: url(~@/assets/img/nav-menu/nav-icon-loyalty.png)
              center/100% 100% no-repeat;
          }
          &.elixir {
            width: 40px;
            height: 53px;
            background: url(~@/assets/img/common/elixir.png) center/100% 100%
              no-repeat;
          }
          &.expbooster {
            width: 50px;
            height: 45px;
            margin-top: 3px;
            background: url(~@/assets/img/shop/level-turbo-6-x-copy@2x.png)
              center/100% 100% no-repeat;
          }
        }
      }
    }
  }
  .submit-button {
    width: 236px;
    height: 95px;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    cursor: pointer;
    text-align: center;
    line-height: 1;
    left: 362px;
    bottom: 33px;
    position: absolute;
    p {
      text-shadow: 0 0 12px #0a2700, 0 0 4px #0a2700;
    }

    .big {
      font-size: 48px;
    }

    .offer-old-price {
      top: -50px;
      right: -90px;
    }

    .big-badge-more-percent {
      top: -470px;
      right: -130px;
    }
  }

  .badge-big {
    width: 253px;
    height: 333px;
    background: url(~@/assets/img/shop/offers/bg-counter-badge.svg) center/100%
      100% no-repeat;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 45px;
    top: 217px;
    text-shadow: 0 1px 6px rgba(0, 0, 0, 0.5), 0 1px 6px rgba(0, 0, 0, 0.5),
      0 1px 6px rgba(0, 0, 0, 0.5);
    .badge-text {
      color: white;
      text-transform: uppercase;
      font-family: Ubuntu-Bold;
      text-align: center;
      font-size: 31.8px;
      transform: rotate(-10deg);
      position: absolute;
      left: 71px;
      top: 50px;
      line-height: 1;
      width: 100px;
      .big {
        font-family: Ubuntu-Bold;
        font-size: 48px;
      }
    }
    .countdown-box {
      height: 80px;
      bottom: 12px;
      font-size: 18px;
      line-height: 1.2;
      .countdown-digital {
        font-size: 38px;
      }
    }
  }
}
</style>
