const Seo = {
  install(Vue, options) {
    Vue.mixin({
      metaInfo() {
        const defaultDescription =
          "Meine Krone ist ein Social Casino. Auf der Plattform werden verschiedene Online-Spiele, sowie zusätzliche Unterhaltung und Dienste angeboten.";

        if (!this.$route || !this.$route.meta || !this.$route.meta.seoTitle) {
          return;
        }
        return {
          title: this.$route.meta.seoTitle,
          titleTemplate: "%s | Meine Krone",
          meta: [
            {
              property: "og:title",
              content: "Meine Krone - " + this.$route.meta.seoTitle,
            },
            {
              name: "twitter:title",
              content: "Meine Krone - " + this.$route.meta.seoTitle,
            },

            {
              property: "og:url",
              content: "https://www.kleinekrone.de" + this.$route.path,
            },
            {
              name: "twitter:url",
              content: "https://www.kleinekrone.de" + this.$route.path,
            },
            // {
            //   vmid: "description",
            //   name: "description",
            //   content: this.$route.meta.seoDescription || defaultDescription,
            // },
            // {
            //   vmid: "og:description",
            //   property: "og:description",
            //   content: this.$route.meta.seoDescription || defaultDescription,
            // },
            // {
            //   vmid: "twitter:description",
            //   name: "twitter:description",
            //   content: this.$route.meta.seoDescription || defaultDescription,
            // },
          ],
          link: [
            {
              vmid: "link-canonical",
              rel: "canonical",
              href: "https://www.kleinekrone.de" + this.$route.path,
            },
          ],
        };
      },
    });
  },
};
export default Seo;
